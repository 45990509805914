.link {
  text-transform: uppercase;
  font-size: 12px;
  white-space: nowrap;
}

.icon {
  fill: var(--highlight);
  display: inline-block;
  vertical-align: middle;
  margin: -2px 6px 0 0;
  width: 16px;
  height: 16px;
}

.link:hover {
  .label {
    text-decoration: underline;
  }
}
