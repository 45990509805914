.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin: -2px 6px 0 -5px;
}

.tag {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 6px 15px 5px;
  margin: 6px;
  text-transform: uppercase;
  border: 1px solid var(--tag);
  border-radius: 13px;
  background: var(--tag);
  font-size: 14px;
  line-height: 1;
  overflow: hidden;
  text-decoration: none;
  transition: all 150ms ease-in-out;

  &:hover,
  &:focus,
  &.active {
    background-color: #05164d;
    color: #fff;
    cursor: pointer;
    fill: #fff;
  }

  &.active {
    .icon {
      transform: scale(0.8);
      color: #fff;
    }
  }
}

.tags {
  text-align: center;
  margin: 0 auto 60px;
  max-width: 600px;
}
