.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.jiraBoardContainer{
    background-color:#f4f5f7;
    min-width: 25%;
    display: flex;
    gap: 1px;
    flex-direction: column;
    padding: 10px;
}
.jiraCard{
    background: #fff;
    color: #172b4d;
    cursor: move;
    display: block;
    font-size: 14px;
    line-height: 1.42857143;
    position: relative;
    box-shadow: 0 0 1px 0 rgba(9, 30, 66, 0.31), 0 2px 4px -1px rgba(9, 30, 66, 0.25);
    border-radius: 2px;
    margin: 5px;
    padding: 10px;
    &:hover{
        background-color:#f4f5f7;
    }
}
.jiraCard__content{
    display: flex;
    flex-direction: column;
    
}
.jiraCard__content p{
    font-size: 13px;
    max-width: 25em;
    
}
.jiraCard__content img{
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 10px;
    margin-right: 10px;
    max-width: 30px;
    border-radius: 50%;
}

.jiraCard__content a{
    color: #52627c;
    &:hover{
        text-decoration: underline;
    }
 }
.jiraBoardContainer__header{
    position: relative;
    margin-top: -30px;
    margin-left: 4px;
    font-weight: 700;
}