.legend {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  display: none;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin: -2px 8px 0 0;
}

@media (min-width: 768px) {
  .legend {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translateX(-50%);
  }
}

@media (min-width: 1200px) {
  .legend {
    bottom: auto;
    left: auto;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
