/* Container with left and right margins */
.responsive-iframe-container {
  top: 100px;
  left: 0;
  margin-top: 10px;
  width: calc(100vw - 280px); /* Adjusts width for side margins */
  height: 100vh;
  margin-right: 20px; /* Right margin for sidebar space */
}

/* Responsive iframe that adjusts to fit the container */
.responsive-iframe-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}
