.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 20px;
}

.title {
  margin: 0 30px 0 0;
}

.editLink {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.2s;
}

.revision {
  padding: 30px 0 15px 35px;
  margin-left: 20px;
  border-left: 1px solid #05164d;

  &:hover {
    .editLink {
      opacity: 1;
    }
  }
}

.release {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.2;
  width: 50px;
  color: #fff;
  height: 50px;
  padding: 10px 0;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #05164d;
  float: left;
  margin: -15px 0 0 -60px;
}

.notMaintainedIcon {
  fill: currentColor;
  width: 24px;
  height: 24px;
  margin: 8px auto;
}

.ring {
  float: left;
  margin: -45px 0 0 0;
}

.content {
  position: relative;
  background: var(--content);
  color: var(--text);
  border-radius: 6px;
  padding: 30px 15px;
}

.content a {
  color: var(--link);
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .revision {
    padding: 30px 0 15px 50px;
    margin-left: 38px;
  }

  .release {
    font-size: 18px;
    width: 75px;
    height: 75px;
    padding: 15px 0;
    margin: -15px 0 0 -90px;
  }

  .ring {
    margin-left: -15px;
  }

  .content {
    padding: 30px;
  }
}

/* special styles for revisions without content */
.revision.noContent {
  .content {
    background: none;
  }

  .ring {
    margin-top: -20px;
  }
}

.revision.hint {
  .content {
    font-size: 14px;
    background: var(--border);
    color: var(--foreground);
  }
}
.subContent{
  border-radius: 10px;
  border-width: 1px;
  border-style: groove;
}