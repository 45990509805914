.content {
  margin-bottom: 60px;
  margin: 100px;
  
}

.subContent{
  border-radius: 10px;
    border-width: 1px;
    border-style: groove;
}

.ringAndQuadrant {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border);
}

@media (min-width: 1024px) {
  .layout {
    display: flex;
  }
  .sidebar {
    width: 360px;
    margin-right: 100px;
    padding: 110px 0 0 60px;
  }
  .content {
    flex: 1;
  }
}
.back{
  cursor: pointer;
}