.markdown-content table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .markdown-content th {
    background-color: #f2f2f2;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  .markdown-content a {
    color: blue;
    &:hover{
      cursor: pointer;
    }
  }
 
  
  .markdown-content td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .markdown-content tr:nth-child(even) {
    background-color: #f2f2f2;
  }

.markDownStatus_accepted{
  color: #05164d;
  color: black;
  border-width: 1px;
  border-radius: 30px;
  border-style: double;
  padding: 5px;
}
.markDownStatus_proposed{
  color: black;
  border-color: grey;
  border-width: 1px;
  border-radius: 30px;
  border-style: double;
  padding: 5px;
}