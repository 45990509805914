.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 900px;
  height: 200px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.modalActions {
  margin-top: -20px;
  display: flex;
  justify-content: flex-end;
}

.modalActions button {
  margin-left: 10px;
}

.jss18304{
  display: none!important;
}
