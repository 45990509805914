.radar {
  padding: 0 15px 30px;
  position: relative;
  transition: padding 200ms ease-in-out;
}

.chart {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  fill: currentColor;
}

.tooltip {
  background-color: var(--tooltip, var(--background));
  color: var(--foreground);
  font-size: 14px;
  padding: 4px 8px;
  height: fit-content;
  width: fit-content;
  border-radius: 6px;
  position: absolute;
  text-align: center;
  opacity: 0;
  transform: translate(-50%, -90%) scale(0.7);
  transform-origin: 50% 100%;
  transition:
    all 100ms ease-in-out,
    left 0ms,
    top 0ms;
  box-shadow:
    0 4px 14px 0 rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  z-index: 1;

  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    bottom: -1px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid var(--tooltip, var(--background));
    transition: bottom 100ms ease-in-out;
  }

  &.isShown {
    opacity: 1;
    transform: translate(-50%, -130%) scale(1);

    &:before {
      bottom: -7px;
    }
  }
}

@media (max-width: 767px) {
  .labels {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .radar {
    padding: 150px 15px;
  }
}
