.adrLink{
    color: #05164d;
    font-size: 23px;
    font-weight: 700;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}
.adrContent{
    color:rgba(0, 0, 0, 0.54)
}
.adrSubContentContainer{
    display: flex;
    flex-direction: row;
    gap:20px;
    margin-top: 20px;
}
.adrSubContentContainer__content{
    background-color: #e0e0e0;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 50px;
    white-space: nowrap;
}