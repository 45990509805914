.rings {
  --cols: 1;
  --gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}

.ring {
  margin-bottom: 20px;
  flex: 1 0
    calc(100% / var(--cols) - var(--gap) / var(--cols) * (var(--cols) - 1));
}

.badge {
  margin-bottom: 20px;
}

@media (min-width: 480px) {
  .rings {
    --cols: 2;
  }
}

@media (min-width: 768px) {
  .rings.isSmall {
    --cols: 4;
  }
}

@media (min-width: 1024px) {
  .rings {
    --cols: 4;
  }
}
